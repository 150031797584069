<template>
  <div id="vehicle_types">
    <div class="vehicleTypes">
      <div class="main-title vehicleTitle">
        {{$t('vehicleTypes.title')}}
      </div>
      <div class="description">
        {{$t('vehicleTypes.description')}}
      </div>
      <div class="vehicles" v-if="staticCar">
<!--        <div class="vehiclesGroup">-->
        <div class="vehiclesContainer">
          <div class="imageContainer">
            <img src="../../assets/images/motor.png" class="motor" style="margin-left: 0 !important;">
          </div>
          <div class="titleContainer">
            {{$t('vehicleTypes.motors')}}
          </div>
        </div>
        <div class="vehiclesContainer">
          <div class="imageContainer">
            <img src="../../assets/images/bus.png" class="bus" style="margin-left: 0 !important;">
          </div>
          <div class="titleContainer">
            {{$t('vehicleTypes.buses')}}
          </div>
        </div>
<!--        </div>-->
<!--        <div class="vehiclesGroup">-->
        <div class="vehiclesContainer">
          <div class="imageContainer">
            <img src="../../assets/images/car.png" class="car" style="margin-left: 0 !important;">
          </div>
          <div class="titleContainer">
            {{$t('vehicleTypes.cars')}}
          </div>
        </div >
        <div class="vehiclesContainer">
        <div class="imageContainer">
          <img src="../../assets/images/pickup.png"  class="pickup" style="margin-left: 0 !important;">
        </div>
        <div class="titleContainer">
          {{$t('vehicleTypes.pickUp')}}
        </div>
      </div>
        <div class="vehiclesContainer">
          <div class="imageContainer">
            <img src="../../assets/images/truck.png"  class="truck" style="margin-right: 0 !important;">
          </div>
          <div class="titleContainer">
            {{$t('vehicleTypes.trucks')}}
          </div>
        </div>
<!--        </div>-->
      </div>
      <VueSlickCarousel
        ref="slider"
        :key="componentKey"
        :rtl="direction"
        v-bind="settings"
        v-if="slider" :autoplay="true" :pauseOnHover="false" :autoplaySpeed="3000" @afterChange="changeActiveElement"
      >
        <div  v-for="(item, i) in info" :key="i">
          <div class="vehiclesContainer" :id="i" :style="elementNumber === i ? 'opacity: 1' : 'opacity: 0.5'">
            <div class="imageContainer">
              <img :src="item.image"  :class="$vuetify.rtl ? item.styles : item.enStyle">
            </div>
            <div class="titleContainer" :class="$vuetify.rtl ? item.titleStyle : item.enTitleStyle" :style="elementNumber === i ? 'color: #FFAC00' : ''">
              {{item.title}}
            </div>
          </div>
        </div>
      </VueSlickCarousel>
      <div :class="$vuetify.rtl?'arLeftArrow':'enLeftArrow'" @click="handleNext" class="leftArrow" v-if="slider">
        <img src="../../assets/icons/leftArrow.svg" style="width: 12px; height: 12px" />
      </div>
      <div :class="$vuetify.rtl?'arRightArrow':'enRightArrow'" @click="handlePrev" class="rightArrow" v-if="slider">
        <img src="../../assets/icons/rightArrow.svg" style="width: 12px; height: 12px"/>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel-rtl'
import 'vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css'
import 'vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css'
// import $ from 'jquery'
export default {
  name: 'VehicleTypes',
  components: { VueSlickCarousel },
  data () {
    return {
      info: [{
        title: this.$t('vehicleTypes.motors'),
        image: require('../../assets/images/motor.png'),
        styles: 'motor',
        enStyle: 'enMotor',
        titleStyle: 'motorMargin',
        enTitleStyle: 'enMotorMargin'
      },
      {
        title: this.$t('vehicleTypes.buses'),
        image: require('../../assets/images/busTest.png'),
        styles: 'bus',
        enStyle: 'enBus',
        titleStyle: 'busMargin',
        enTitleStyle: 'enBusMargin'
      },
      {
        title: this.$t('vehicleTypes.cars'),
        image: require('../../assets/images/carTest.png'),
        styles: 'car',
        enStyle: 'enCar',
        titleStyle: 'carMargin',
        enTitleStyle: 'enCarMargin'
      },
      {
        title: this.$t('vehicleTypes.pickUp'),
        image: require('../../assets/images/pickupTest.png'),
        styles: 'pickup',
        enStyle: 'enPickup',
        titleStyle: 'pickUpMargin',
        enTitleStyle: 'enPickUpMargin'
      },
      {
        title: this.$t('vehicleTypes.trucks'),
        image: require('../../assets/images/truck.png'),
        styles: 'truck',
        enStyle: 'enTruck',
        titleStyle: 'truckMargin',
        enTitleStyle: 'enTruckMargin'
      }],
      currentSlide: 0,
      componentKey: 1,
      direction: this.$vuetify.rtl,
      change: true,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        initialSlide: 1,
        centerMode: true,
        centerPadding: '250',
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1840,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '100'
            }
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '40'
            }
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '200'
            }
          },
          {
            breakpoint: 1130,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '120'
            }
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '100'
            }
          },
          {
            breakpoint: 920,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '250'
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '240'
            }
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '180'
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '110'
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '110'
            }
          },
          {
            breakpoint: 620,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '90'
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerPadding: '0'
            }
          }
        ]
      },
      slider: false,
      staticCar: true,
      elementNumber: 0
    }
  },
  mounted () {
    if (this.slider === true) {
      this.elementNumber = this.$refs.slider.$refs.innerSlider.currentSlide
      this.changeActiveElement()
    }
    // this.$watch(() => {
    //   return this.$vuetify.rtl
    // }, val => {
    //   this.direction = val
    //   this.$refs.slider.$forceUpdate()
    //   this.componentKey++
    //   setTimeout(() => {
    //     this.handleWatch()
    //   })
    // })
    // this.handleWatch()
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      this.direction = val
      // this.$refs.slider.$forceUpdate()
      this.info[0].title = this.$t('vehicleTypes.motors')
      this.info[1].title = this.$t('vehicleTypes.buses')
      this.info[2].title = this.$t('vehicleTypes.cars')
      this.info[3].title = this.$t('vehicleTypes.pickUp')
      this.info[4].title = this.$t('vehicleTypes.trucks')
    })
    window.addEventListener('resize', () => {
      this.hideSlider()
      // this.changeActiveElement()
    })
    // this.$watch(() => {
    //   return this.$vuetify.rtl
    // }, val => {
    //   this.info[0].title = this.$t('vehicleTypes.motors')
    //   this.info[1].title = this.$t('vehicleTypes.buses')
    //   this.info[2].title = this.$t('vehicleTypes.cars')
    //   this.info[3].title = this.$t('vehicleTypes.pickUp')
    // })
  },
  created () {
    this.hideSlider()
  },
  methods: {
    changeActiveElement () {
      if (this.slider === true) {
        this.elementNumber = this.$refs.slider.$refs.innerSlider.currentSlide
        this.$watch(
          () => {
            return this.$refs.slider.$refs.innerSlider.currentSlide
          },
          (val) => {
            this.elementNumber = val
          }
        )
      }
    },
    // handleWatch() {
    //   this.$watch(() => {
    //     return this.$refs.slider.$children[0].currentSlide
    //   }, val => {
    //     this.currentSlide = val
    //   })
    // },
    hideSlider () {
      if (window.innerWidth > 1840) {
        this.staticCar = true
        this.slider = false
      } else if (window.innerWidth < 1840) {
        this.staticCar = false
        this.slider = true
      }
      // console.log('slider', this.slider)
      // console.log('car', this.staticCar)
    },
    handleNext () {
      this.$refs.slider.next()
      this.changeActiveElement()
      // console.log('pppppp', this.$refs.slider.$refs.innerSlider.currentSlide)
      // console.log('fff', this.info[this.$refs.slider.$refs.innerSlider.currentSlide])
      // this.elementNumber = this.$refs.slider.$refs.innerSlider.currentSlide + 1
    },
    handlePrev () {
      this.$refs.slider.prev()
      this.changeActiveElement()
      // this.elementNumber = this.$refs.slider.$refs.innerSlider.currentSlide - 1
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/main";
#vehicle_types{
  margin-top: -70px;
  padding-top: 70px;
}
  .vehicleTypes{
    background-image: url("../../assets/images/vehiclesType.png");
    background-size: cover;
    position: relative;
  }
  .vehicleTitle{
    padding: 5vh 2vw 1vh 2vw;
  }
  .description{
    color: #FFFFFF;
    padding: 0px 25vw;
    font-family: 'lama-regular';
  }
  .vehicles{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .vehiclesContainer{
    margin: 5vh 1.5vw;
  }
@media (max-width: 1840px) {
  .vehiclesContainer{
    margin: 5vh 2vw;
  }
  }
  /*.imageContainer{*/
  /*  height: 20rem;*/
  /*}*/
  .titleContainer{
    font-family: lama-extraBoldExpanded;
    color: white;
    font-size: 15px;
  }
  .imageContainer {
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    pointer-events: none;
  }
.vehiclesGroup {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
}
  .bus{
    width: 375px;
    height: 140px;
    margin-top: 13px;
    margin-left: 43px;
  }
  .motor{
    height: 110px;
    width: 200px;
    margin-top: 40px;
    margin-left: -26px;
  }
  .car{
    margin-top: 40px;
    margin-left: 40px;
  }
  .pickup{
    margin-top: 27px;
    margin-left: -30px;
  }
  .truck{
    width: 300px;
    height: 170px;
    margin-top: 8px;
    margin-right: 125px
  }
.enBus{
  width: 375px;
  height: 140px;
  margin-top: 13px;
  margin-right: 43px;
}
.enMotor{
  height: 110px;
  width: 200px;
  margin-top: 40px;
  margin-right: -26px;
}
.enCar{
  margin-top: 40px;
  margin-left: 40px;
}
.enPickup{
  margin-top: 27px;
  margin-right: -95px;
}
.enTruck{
  width: 300px;
  height: 170px;
  margin-top: 8px;
  margin-right: -125px
}
.busMargin{
  margin-left: 43px;
}
.motorMargin{
  margin-left: -26px;
}
.carMargin{
  margin-left: 40px;
}
.truckMargin{
  margin-right: 125px
}
.pickUpMargin{
  margin-left: -30px;
}
.enBusMargin{
  margin-right: 43px;
}
.enMotorMargin{
  margin-right: -26px;
}
.enCarMargin{
  margin-right: 40px;
}
.enPickUpMargin{
  margin-right: -95px;
}
.enTruckMargin{
  margin-right: -125px
}
@media (max-width: 600px) {
  .bus{
    width: 375px;
    height: 140px;
    margin-top: 13px;
    margin-left: 0;
  }
  .motor{
    height: 110px;
    width: 200px;
    margin-top: 40px;
    margin-left: 0px;
  }
  .car{
    margin-top: 40px;
    margin-left: 0px;
  }
  .pickup{
    margin-top: 27px;
    margin-left: 0px;
  }
  .truck {
    margin-right: 0px
  }
  .enBus{
    width: 375px;
    height: 140px;
    margin-top: 13px;
    margin-right: 0px;
  }
  .enMotor{
    height: 110px;
    width: 200px;
    margin-top: 40px;
    margin-right: 0px;
  }
  .enCar{
    margin-top: 40px;
    margin-left: 0px;
  }
  .enPickup{
    margin-top: 27px;
    margin-right: 0px;
  }
  .enTruck{
    margin-right: 0px
  }
  .busMargin{
    margin-left: 0px;
  }
  .motorMargin{
    margin-left: 0px;
  }
  .carMargin{
    margin-left: 0px;
  }
  .pickUpMargin{
    margin-left: 0px;
  }
  .truckMargin{
    margin-right: 0px
  }
  .enBusMargin{
    margin-right: 0px;
  }
  .enMotorMargin{
    margin-right: 0px;
  }
  .enCarMargin{
    margin-right: 0px;
  }
  .enPickUpMargin{
    margin-right: 0px;
  }
  .enTruckMargin{
    margin-right: 0px
  }
}
  .leftArrow{
    width: 55px;
    background: #f7f7f738;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 11rem;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    user-select: none;
    cursor: pointer;
  }
  .rightArrow{
    width: 55px;
    background: #f7f7f738;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 11rem;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    user-select: none;
    cursor: pointer;
  }
.arLeftArrow{
  left: 0;
}
.enLeftArrow{
  right: 0;
  transform: rotateY(180deg);
}
.arRightArrow{
  right: 0;
}
.enRightArrow{
  left: 0;
  transform: rotateY(180deg);
}
@media (max-width: 900px) {
  .description {
    padding: 0px 10vw;
  }
}
</style>
