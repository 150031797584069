<template>
  <div id="about">
    <div class="main-container position-relative about" v-on:mouseover="hoverOn(true)" v-on:mouseleave="hoverOn(false)">
      <div class="opacity-0 cont-pad">
        <div class="main-title">{{$t('who')}}</div>
        <div class="b-description lama-bold"> {{ $t('aboutPart1') }} </div>
        <br>
        <div class="b-description"> {{ $t('aboutPart2') }} </div>
        <div class="black-medium"> {{ $t('inspection')}} </div>
        <figure class="image" :class="$vuetify.rtl ? 'translate-8' : 'translate-2'" dir="rtl">
          <img class="vehicle" src="../../assets/images/vehicle-lowQuality.png" alt="vehicle" @load="onImageLoad">
          <!--       <img v-else class="vehicle" src="../../assets/images/hovered.png" alt="vehicle">-->
          <div v-if="vehicleLoaded">
            <div class="hovered-items" v-for="(item, i) in pointsTemp" :class="isVisible ? 'opacity-100' : 'opacity-0'" :key="i">
              <div class="point" :style="{'right': item.arRight, 'top': item.arTop}">
                <div class="outer-circle">
                  <div class="inner-circle"></div>
                </div>
                <div class="position-relative">
                  <div class="dashed-line" :style="[{'right': arrowsTemp[i].arRight, 'top': arrowsTemp[i].arTop} , { 'width' : arrowsTemp[i].width}]">
                    <img class="w-100 h-100" :style="i === 7 ?  { transform: 'rotateX(180deg) rotateY(-180deg)'} : {}" :src="require(`../../assets/icons/${imageName}${i+1}.svg`)" alt="DashedLine">
                    <div class="position-relative w-100 h-100" style="direction: ltr">
                      <div class="position-absolute" :style="{'right': descTemp[i].right, 'width': descTemp[i].width, 'top': descTemp[i].top }">
                        <!--                    <div v-if="$store.state.width >= 1001" class="desc" :style="{'right': descTemp[i].right, 'width': descTemp[i].width, 'top': descTemp[i].top }">-->
                        <div v-if="$store.state.width >= 1001" class="desc">
                          {{$t(`inspectionPoints[${i}]`)}}
                        </div>
                        <!--                    <div v-else class="desc" :style="{'right': descTemp[i].right, 'width': descTemp[i].width, 'top': descTemp[i].top }">-->
                        <div v-else class="desc">
                          {{$t(`linesSm[${i}]`)}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img  v-observe-visibility="visibilityChanged" class="lights" src="../../assets/images/lights.png" alt="VehicleLights" :class="isVisible ? 'opacity-100' : 'opacity-0'">
          </div>
        </figure>
      </div>
      <div class="data-layer cont-pad">
        <div class="main-title">{{$t('who')}}</div>
        <div class="b-description lama-bold"> {{ $t('aboutPart1') }} </div>
        <br>
        <div class="b-description"> {{ $t('aboutPart2') }} </div>
        <div class="black-medium"> {{ $t('inspection')}} </div>
        <figure class="image" :class="$vuetify.rtl ? 'translate-8' : 'translate-2'" dir="rtl">
          <img class="vehicle" src="../../assets/images/vehicle-lowQuality.png" alt="vehicle" @load="onImageLoad">
          <!--       <img v-else class="vehicle" src="../../assets/images/hovered.png" alt="vehicle">-->
          <div v-if="vehicleLoaded">
            <div class="hovered-items" v-for="(item, i) in pointsTemp" :class="isVisible ? 'opacity-100' : 'opacity-0'" :key="i">
              <div class="point" :style="{'right': item.arRight, 'top': item.arTop}">
                <div class="outer-circle">
                  <div class="inner-circle"></div>
                </div>
                <div class="position-relative">
                  <div class="dashed-line" :style="[{'right': arrowsTemp[i].arRight, 'top': arrowsTemp[i].arTop} , { 'width' : arrowsTemp[i].width}]">
                    <img class="w-100 h-100" :style="i === 7 ?  { transform: 'rotateX(180deg) rotateY(-180deg)'} : {}" :src="require(`../../assets/icons/${imageName}${i+1}.svg`)" alt="DashedLine">
                    <div class="position-relative w-100 h-100" style="direction: ltr">
                      <div class="position-absolute" :style="{'right': descTemp[i].right, 'width': descTemp[i].width, 'top': descTemp[i].top }">
                        <!--                    <div v-if="$store.state.width >= 1001" class="desc" :style="{'right': descTemp[i].right, 'width': descTemp[i].width, 'top': descTemp[i].top }">-->
                        <div v-if="$store.state.width >= 1001" class="desc">
                          {{$t(`inspectionPoints[${i}]`)}}
                        </div>
                        <!--                    <div v-else class="desc" :style="{'right': descTemp[i].right, 'width': descTemp[i].width, 'top': descTemp[i].top }">-->
                        <div v-else class="desc">
                          {{$t(`linesSm[${i}]`)}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img  v-observe-visibility="visibilityChanged" class="lights" src="../../assets/images/lights.png" alt="VehicleLights" :class="isVisible ? 'opacity-100' : 'opacity-0'">
          </div>
        </figure>
      </div>
      <div class="clipped-layer"></div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
export default {
  name: 'About',
  data () {
    return {
      vehicleLoaded: false,
      hovered: false,
      pointsTemp: [],
      arrowsTemp: [],
      descTemp: [],
      imageName: '',
      isVisible: false,
      points: {
        large: [
          { arTop: '22%', arRight: '50.5%' },
          { arTop: '5%', arRight: '43%' },
          { arTop: '41%', arRight: '79.5%' },
          { arTop: '74%', arRight: '67.5%' },
          { arTop: '75%', arRight: '44.5%' },
          { arTop: '66%', arRight: '20%' },
          { arTop: '30%', arRight: '17%' },
          { arTop: '50%', arRight: '17%' }
        ],
        med: [
          { arTop: '18%', arRight: '51.5%' },
          { arTop: '6%', arRight: '37%' },
          { arTop: '41%', arRight: '89.5%' },
          { arTop: '74%', arRight: '72.5%' },
          { arTop: '75%', arRight: '40.5%' },
          { arTop: '66%', arRight: '10%' },
          { arTop: '30%', arRight: '4%' },
          { arTop: '50%', arRight: '4%' }
        ],
        sm: [
          { arTop: '15%', arRight: '52.5%' },
          { arTop: '3%', arRight: '37%' },
          { arTop: '41%', arRight: '89.5%' },
          { arTop: '74%', arRight: '74.5%' },
          { arTop: '71%', arRight: '39.5%' },
          { arTop: '66%', arRight: '13%' },
          { arTop: '23%', arRight: '4%' },
          { arTop: '48%', arRight: '3%' }
        ]
      },
      arrows: {
        large: [
          { arTop: '-39px', arRight: '21px', width: '248px' },
          { arTop: '-46px', arRight: '-73px', width: '80px' },
          { arTop: '-49px', arRight: '11.5px', width: '105px' },
          { arTop: '5px', arRight: '11.5px', width: '158px' },
          { arTop: '-4px', arRight: '-137px', width: '141px' },
          { arTop: '-17.5px', arRight: '-121px', width: '119px' },
          { arTop: '-42px', arRight: '-95px', width: '105px' },
          { arTop: '-46px', arRight: '-102px', width: '105px' }
        ],
        med: [
          { arTop: '-19px', arRight: '27px', width: '167px' },
          { arTop: '-70px', arRight: '25px', width: '41px' },
          { arTop: '-103px', arRight: '-15.5px', width: '30px' },
          { arTop: '-11px', arRight: '20px', width: '28px' },
          { arTop: '-12px', arRight: '-8px', width: '26px' },
          { arTop: '-10.5px', arRight: '20.5px', width: '57px' },
          { arTop: '-90px', arRight: '17px', width: '41px' },
          { arTop: '-9px', arRight: '-14px', width: '14px' }
        ],
        sm: [
          { arTop: '-18px', arRight: '18px', width: '59px' },
          { arTop: '-56px', arRight: '18px', width: '34px' },
          { arTop: '-90px', arRight: '-12.5px', width: '15px' },
          { arTop: '-8px', arRight: '16px', width: '21px' },
          { arTop: '-5px', arRight: '-8px', width: '10px' },
          { arTop: '-9.5px', arRight: '16.5px', width: '21px' },
          { arTop: '-66px', arRight: '17px', width: '16px' },
          // { arTop: '-66px', arRight: '17px', width: '16px' },
          { arTop: '-2px', arRight: '1px', width: '14px' }
          // { arTop: '17%', arRight: '59%', width: '13%' },
          // { arTop: '-8%', arRight: '44%', width: '3%' },
          // { arTop: '17%', arRight: '87.5%', width: '2.5%' },
          // { arTop: '79%', arRight: '80.5%', width: '2.4%' },
          // { arTop: '78%', arRight: '38%', width: '2%' },
          // { arTop: '71.5%', arRight: '17%', width: '4%' },
          // { arTop: '4%', arRight: '10%', width: '3.5%' }
        ]
      },
      desc: {
        large: [
          { right: '-104px', top: '-21px', width: '192px' },
          { right: '-182px', top: '-21px', width: '191px' },
          { right: '-87px', top: '-21.5px', width: '175px' },
          { right: '-79px', top: '26px', width: '155px' },
          { right: '-217px', top: '28.5px', width: '141px' },
          { right: '-195.5px', top: '19px', width: '146px' },
          { right: '-190.5px', top: '-21px', width: '170px' },
          { right: '-201.5px', top: '-15px', width: '190px' }
        ],
        med: [
          { right: '6px', top: '-17px', width: '20px' },
          { right: '-54.5px', top: '-17px', width: '107px' },
          { right: '-68.8px', top: '-19px', width: '108px' },
          { right: '-47px', top: '56px', width: '94px' },
          { right: '-54px', top: '98.5px', width: '90px' },
          { right: '5.5px', top: '69px', width: '20px' },
          { right: '-54.5px', top: '-18px', width: '103px' },
          { right: '-54.5px', top: '55px', width: '84px' }
        ],
        sm: [
          { right: '-8px', top: '-17px', width: '20px' },
          { right: '-59.5px', top: '-14px', width: '107px' },
          { right: '-61.8px', top: '-17px', width: '91px' },
          { right: '-56px', top: '43px', width: '94px' },
          { right: '-62px', top: '76.5px', width: '90px' },
          { right: '-1.5px', top: '50px', width: '20px' },
          { right: '-61.5px', top: '-14px', width: '103px' },
          // { right: '-61.5px', top: '-18px', width: '103px' },
          { right: '-51.5px', top: '54px', width: '64px' }
          // { right: '62%', top: '9%', width: '16%' },
          // { right: '30.5%', top: '-16%', width: '34%' },
          // { right: '78.2%', top: '-1%', width: '17%' },
          // { right: '69%', top: '97%', width: '30%' },
          // { right: '24%', top: '107.5%', width: '30%' },
          // { right: '6.5%', top: '92%', width: '20%' },
          // { right: '-2.5%', top: '-4%', width: '34%' }
        ]
      }
    }
  },
  created () {
    // this.$watch(() => {
    //   return this.$vuetify.rtl
    // }, val => {
    //   this.$vuetify.rtl = true
    // })
    this.handlePoints()
    window.addEventListener('resize', this.handlePoints)
    // window.addEventListener('resize', () => {
    //   var perc = parseInt($(window).innerWidth()) / 75
    //   $('.desc').css('font-size', perc)
    // })
  },
  destroyed () {
  },
  methods: {
    onImageLoad (data) {
      this.vehicleLoaded = true
    },
    is_mouse_enabled () {
      return matchMedia('(pointer:fine)').matches
    },
    visibilityChanged (data) {
      if (this.is_mouse_enabled()) {
        this.isVisible = false
      } else {
        this.isVisible = data
      }
    },
    hoverOn (val) {
      this.hovered = val
    },
    handlePoints () {
      this.imageName = ''
      if (window.innerWidth >= 1001) {
        this.pointsTemp = this.points.large
        this.arrowsTemp = this.arrows.large
        this.descTemp = this.desc.large
        this.imageName = 'inspection'
      } else if (window.innerWidth <= 1000 && window.innerWidth > 600) {
        this.pointsTemp = this.points.med
        this.arrowsTemp = this.arrows.med
        this.descTemp = this.desc.med
        this.imageName = 'line'
      } else if (window.innerWidth <= 600) {
        this.pointsTemp = this.points.sm
        this.arrowsTemp = this.arrows.sm
        this.descTemp = this.desc.sm
        this.imageName = 'line'
      }
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/main";
.black-medium, .lama-bold {
  word-spacing: 3px;
}
#about{
  margin-top: -20px;
  padding-top: 20px;
  //margin-top: -70px;
  //padding-top: 70px;
}
.about {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.data-layer {
  //height: 100
  position: absolute;
  top: 0; z-index: 9; right: 0;
}
.cont-pad {
  padding: 0 6vw 0.5rem 6vw!important
}
@media screen and (max-width: 500px) {
  .cont-pad {
    padding: 0 3vw 0.5rem 3vw!important
  }
}
.clipped-layer {
  position: absolute;
  height: 100% !important;
  top: 0;
  z-index: 8;
  background: url("../../assets/images/LightYellow.png");
  background-size: cover;
  //background-color:rgba(255, 172, 0, 0.16);
  //backdrop-filter: blur(0) brightness(30) opacity(16%);
  height: 100vh;
  width: 72.2%;
  right: 0;
  clip-path: polygon( 57.843% 100%,94.668% 0%,100% 0%,100% 100%,0% 100%,71.256% 0%,89.99% 0%,39.595% 100% );
}
//.clipped-layer.ar {
//  right: 0;
//  clip-path: polygon( 57.843% 100%,94.668% 0%,100% 0%,100% 100%,0% 100%,71.256% 0%,89.99% 0%,39.595% 100% );
//}
//.clipped-layer.en {
//  right: 0;
//  transform: rotate(180deg);
//  clip-path: polygon( 57.951% 0%,94.844% 100%,100% 100%,100% 0%,39.669% 0%,90.157% 100%,71.388% 100%,0% 0% );
//}
@media screen and (max-width: 1000px) and (min-width: 470px) {
  .clipped-layer {
    width: 200%!important;
    right: -42%;
  }
  //.clipped-layer.ar {
  //  right: -42%;
  //}
  //.clipped-layer.en {
  //  left: -42%;
  //}
}
@media screen and (max-width: 470px) {
  .clipped-layer {
    width: 200%!important;
    right: -45%;
  }
  //.clipped-layer.ar {
  //  right: -45%;
  //}
  //.clipped-layer.en {
  //  left: -45%;
  //}
}
@media screen and (max-width: 1000px) {
  .clipped-layer {
    background: rgba(255, 255, 255, 0.8)!important;
    //background: #F7F7F7 !important; opacity: 51;
  }
  .about {
    //background: #EBEBEB;
  }
}
.black-medium {
  margin: 1.5rem 0 4rem 0!important;
}
.image .vehicle {
  width: 75%;
  height: 75%;
  //min-height: 320px
}
.image {
  position: relative;
}
.translate-2 {
  transform: translateX(-8px);
}
.translate-8 {
  transform: translateX(-8px);
}
.point{
  position: absolute;
  width: 25px; height: 25px;
}
@media screen and (max-width: 1000px){
  .point {
    width: 20px !important;
    height: 20px !important;
  }
}
@media screen and (max-width: 600px){
  .point {
    width: 16px !important;
    height: 16px !important;
  }
  .black-medium {
    font-size: 16px!important;
  }
}
.dashed-line {
  position: absolute;
  display: flex;
  //width: 0px; height: 0px;
}
.desc {
  position: absolute;
  font-size: 13px;
  color: $--orange;
  font-family: lama-bold-expanded
}
.outer-circle {
  width: 100%; height: 100%;
  border: solid 2px $--orange;
  background: rgba(255, 255, 255 , 0.1);
  border-radius: 50%;
  display: flex; justify-content: center; align-items: center;
  backdrop-filter: blur(3px) brightness(1.5);
  -webkit-backdrop-filter: blur(3px) brightness(1.5);
}
.inner-circle {
  border-radius: 50px;
  width: 60%; height: 60%;
  background: $--orange;
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.3));
}
.hovered-items, .lights {
  opacity: 1;
  transition: all .2s;
}
@media (pointer: fine) {
  /* Rules for devices with mouse here */
  .about:hover .hovered-items {
    opacity: 1!important;
  }
  .about:hover .lights {
    opacity: 1!important;
  }
}
@media screen and (max-width: 1251px) {
  .desc {
    font-size: 13px!important;
  }
}
@media screen and (max-width: 1000px) {
  .desc {
    font-size: 12px!important;
  }
}
@media screen and (max-width: 600px) {
  .desc {
    font-size: 9px!important;
  }
  .dashed-line {
    //height: 10px
  }
}
@media screen and (max-width: 1000px) {
  .about:hover .hovered-items {
    //opacity: 0!important;
  }
  .image .vehicle {
    margin-right: -3%;
    width: 103%!important;
    height: 103%!important;
  }
}
.lights {
  position: absolute;
  top: 36%;
  width: 34.5%;
  left: 16%
}
@media screen and (max-width: 1000px){
  .lights{
    width: 46.5%;
    left: 5%
  }
  .image .vehicle {
    //min-height: 260px;
  }
}
@media screen and (max-width: 600px){
  .b-description {
    font-size: 14px!important;
  }
  .image .vehicle {
    //min-height: 138px;
  }
}
@media screen and (max-width: 325px){
  .hovered-items .dashed-line, .hovered-items .desc {
    display: none;
  }
}
</style>
