<template>
  <div :class="[$vuetify.rtl ? 'card-ar rtl': 'card-en' ,'card']">
    <div class="card-title mb-7 px-2">{{info.title}}</div>
<!--    <div class="card-description mb-6">{{info.desc}}</div>-->
<!--    <div class="card-btn">-->
<!--      <div>{{info.btn}}</div>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  props: {
    info: Object
  },
  data () {
    return {
      // info: {
      //   title: this.$t('slider.cardTitle'),
      //   desc: this.$t('slider.cardDesc'),
      //   btn: this.$t('slider.cardBtn')
      // }
    }
  }
}
</script>
<style lang="scss">
@import "src/assets/sass/main";
.card{
  position: absolute;
  background-color: transparent;
  border: none;
  color: #fff;
  width: max-content;
  width: 580px;
  top: calc((100vh - 231.5px + 35px) / 2); /* 193px */
  z-index: 2 !important;
}
.card-en{
  left: 3rem;
}
.card-ar{
  right: 3rem;
}
.card-title{
  font-family: lama-extraBoldExpanded;
  font-size: 35px;
  color: $--orange;
}
.card-description{
  font-size: 16px;
  line-height: 2;
}
.card-btn{
  height: 63px;
  width: 172px;
  color: $--black;
  font-size: 13px;
  background-color: $--orange;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
}
.card-btn > div {
  font-family: lama-extraBoldExpanded;
}

@media (max-width: 1400px) {
  .card {
    width: 480px;
  }
}
@media (max-width: 900px) {
  .card-ar,
  .card-en{
    left: unset !important;
    right: unset !important;
  }
  .card {
    width: 90%;
    left: 5% !important;
    text-align: center;
  }
  .card-btn{
    margin: 0 auto;
  }
}
@media (max-width: 700px) {
  .card-title {
    font-size: 25px;
  }
  .card-description {
    font-size: 14px;
    word-wrap: break-word;
  }
  .card-btn{
    height: 53px;
    width: 160px;
    border-radius: 20px;
  }
}
@media (max-width: 500px){
  .card{
    width: 95%;
    left: 2.5% !important;
  }
}
@media (max-width: 410px){
  .card-title {
    font-size: 22px;
  }
  .card-description {
    font-size: 12px;
  }
  .card-btn{
    height: 50px;
    width: 140px;
    border-radius: 25px;
    font-size: 10px;
  }
}
@media (max-height: 630px) {
  .card {
    top: calc(630px / 2 - (235px / 2))
  }
}
</style>
