<template>
  <div id="home">
    <top-nav/>
    <side-nav v-click-outside="closeSide"/>
    <slider/>
    <about/>
    <vehicle-types/>
    <service-centers/>
    <why-us/>
    <contact/>
    <a href="https://reservations.doroob-sy.com/" target="_blank">
      <div class="fixed-btn" v-if="showButton">
        <div>{{$t('fixedBtn')}}</div>
      </div>
    </a>
  </div>
</template>

<script>
import Slider from '../Slider'
import About from '../About'
import VehicleTypes from '../VehicleTypes'
import ServiceCenters from '../ServiceCenters'
import WhyUs from '../WhyUs'
import Contact from '../Contact'
import TopNav from '../../Core/Compontents/Navs/TopNav'
import SideNav from '../../Core/Compontents/Navs/SideNav'
export default {
  name: 'home',
  components: { SideNav, TopNav, Contact, WhyUs, ServiceCenters, VehicleTypes, About, Slider },
  data () {
    return {
      showButton: true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    closeSide (val) {
      if (val.clientY > 70) {
        this.$store.state.side = false
      }
    },
    handleScroll () {
      const section = document.getElementById('contact')
      const sectionOffset = section.offsetTop - 300
      const scrollPosition = window.scrollY
      this.showButton = scrollPosition < sectionOffset
    }
  }
}
</script>

<style scoped>
#home {
  background: #F7F7F7;
}
</style>
