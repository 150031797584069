<!--<template>-->
<!--  <div id="slider">-->
<!--    <VueSlickCarousel v-if="change" ref="slider" :key="componentKey" :dots="dots" :rtl="direction">-->
<!--      <div :style="{backgroundImage: 'url(' + img + ')'}"   :class="[!$vuetify.rtl && 'rotate-slider' , 'home-slide']" v-for="(item, i) in info" :key="i">-->
<!--        <div ref="layer" :class="['layer clip-path' , !direction && 'layer-en' ]">-->
<!--        </div>-->
<!--        <home-card />-->
<!--      </div>-->
<!--    </VueSlickCarousel>-->
<!--    <div :class="[$vuetify.rtl ? 'links-ar' : 'links-en', 'links']">-->
<!--      <div>-->
<!--        <a href="https://www.facebook.com" target="_blank">-->
<!--        <div class="link">-->
<!--          <img src="../../assets/icons/facebook.svg" />-->
<!--        </div>-->
<!--        </a>-->
<!--        <a href="https://www.linkedin.com" target="_blank">-->
<!--        <div class="link">-->
<!--          <img src="../../assets/icons/linkedIn.svg" />-->
<!--        </div>-->
<!--        </a>-->
<!--        <a href="https://www.twitter.com" target="_blank">-->
<!--        <div class="link">-->
<!--          <img src="../../assets/icons/twitter.svg" />-->
<!--        </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="slider-base">-->
<!--      <div class="arrows flex-row-reverse">-->
<!--        <div :class="$vuetify.rtl && 'rotate mr-8'" @click="handleNext" class="arrow">-->
<!--          <img src="../../assets/icons/arrow.svg" />-->
<!--        </div>-->
<!--        <div :class="!$vuetify.rtl && 'rotate mr-8'" @click="handlePrev" class="arrow">-->
<!--          <img src="../../assets/icons/arrow.svg" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div :class="[$vuetify.rtl && 'flex-row-reverse' , 'pagination-container']">-->
<!--        <div class="current mr-3">-->
<!--          <span v-if="currentSlide.toString().length">{{ 0 }}</span>-->
<!--          <span>{{currentSlide + 1}}</span>-->
<!--        </div>-->
<!--        <div class="dash mr-8"></div>-->
<!--        <div style="opacity: 0.5" class="total">-->
<!--          <span v-if="info.length.toString().length">{{ 0 }}</span>-->
<!--          <span>{{info.length}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import VueSlickCarousel from 'vue-slick-carousel-rtl'-->
<!--import 'vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css'-->
<!--import 'vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css'-->
<!--import HomeCard from '../../Core/Compontents/cards/HomeCard'-->
<!--import image from '../../assets/images/mechanic.png'-->
<!--export default {-->
<!--  name: 'slider',-->
<!--  components: { VueSlickCarousel, HomeCard },-->
<!--  data () {-->
<!--    return {-->
<!--      dots: this.$store.state.width <= 849,-->
<!--      img: image,-->
<!--      info: [{-->
<!--        title: 'معك على طول الطريق',-->
<!--        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',-->
<!--        btn: 'اكتشف المزيد'-->
<!--      },-->
<!--      {-->
<!--        title: 'معك على طول الطريق',-->
<!--        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',-->
<!--        btn: 'اكتشف المزيد'-->
<!--      },-->
<!--      {-->
<!--        title: 'معك على طول الطريق',-->
<!--        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',-->
<!--        btn: 'اكتشف المزيد'-->
<!--      },-->
<!--      {-->
<!--        title: 'معك على طول الطريق',-->
<!--        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',-->
<!--        btn: 'اكتشف المزيد'-->
<!--      }],-->
<!--      currentSlide: 0,-->
<!--      componentKey: 1,-->
<!--      direction: this.$vuetify.rtl,-->
<!--      change: true-->
<!--    }-->
<!--  },-->
<!--  mounted () {-->
<!--    this.$watch(() => {-->
<!--      return this.$vuetify.rtl-->
<!--    }, val => {-->
<!--      this.direction = val-->
<!--      this.currentSlide = 0-->
<!--      this.componentKey++-->
<!--      this.$refs.slider.$forceUpdate()-->
<!--      setTimeout(() => {-->
<!--        this.handleWatch()-->
<!--      })-->
<!--    })-->
<!--    this.$watch(() => {-->
<!--      return this.$store.state.width-->
<!--    }, (val) => {-->
<!--      this.dots = this.$store.state.width < 850-->
<!--      this.$refs.slider.$forceUpdate()-->
<!--    })-->
<!--    this.handleWatch()-->
<!--  },-->
<!--  methods: {-->
<!--    handleWatch () {-->
<!--      this.$watch(() => {-->
<!--        return this.$refs.slider.$children[0].currentSlide-->
<!--      }, val => {-->
<!--        this.currentSlide = val-->
<!--      })-->
<!--    },-->
<!--    handleNext () {-->
<!--      this.$refs.slider.next()-->
<!--    },-->
<!--    handlePrev () {-->
<!--      this.$refs.slider.prev()-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->
<!--<style lang="scss" scoped>-->
<!--@import "src/assets/sass/main";-->
<!--#slider {-->
<!--  text-align: initial;-->
<!--  height: 100vh;-->
<!--  width: 100vw;-->
<!--  position: relative;-->
<!--  overflow: hidden;-->
<!--  user-select: none;-->
<!--  min-height: 630px;-->
<!--}-->
<!--.home-slide{-->
<!--  background-image: url("../../assets/images/mechanic.png");-->
<!--  height: 100vh;-->
<!--  background-repeat:no-repeat;-->
<!--  background-position:center center;-->
<!--  position: relative;-->
<!--  background-size: cover;-->
<!--  min-height: 630px;-->
<!--}-->
<!--.home-slide.rotate-slider::before{-->
<!--  content: '';-->
<!--  position: absolute;-->
<!--  background-image: url("../../assets/images/mechanic.png");-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  transform: rotateY(180deg);-->
<!--  background-repeat:no-repeat;-->
<!--  background-position:center center;-->
<!--  background-size: cover;-->
<!--}-->
<!--.layer{-->
<!--  position: absolute;-->
<!--  height: calc(100% - 70px);-->
<!--  top: 70px;-->
<!--  width: 100%;-->
<!--  background-color: $&#45;&#45;black;-->
<!--  opacity: 0.88;-->
<!--  z-index: 1 !important;-->
<!--  -webkit-clip-path: polygon(0% 0%, 0% 100%, 0.6% calc(100% - 90px), 41% 10px, 50% 10px, 21% calc(100% - 90px),0% calc(100% - 90px), 24% 101%, 52% 10px, 62% 10px, 49% calc(100% - 90px), 0% calc(100% - 90px), 25% 100%,0 calc(100% - 90px),0 100%, 100% 100%, 100% 0%) !important;-->
<!--  clip-path: polygon(0% 0%, 0% 100%, 0.6% calc(100% - 90px), 41% 10px, 50% 10px, 21% calc(100% - 90px),0% calc(100% - 90px), 24% 101%, 52% 10px, 62% 10px, 49% calc(100% - 90px), 0% calc(100% - 90px), 25% 100%,0 calc(100% - 90px),0 100%, 100% 100%, 100% 0%) !important;-->
<!--}-->
<!--.layer-en{-->
<!--  clip-path: polygon(0% 0%, 0% 100%, 25.6% 89%, 66.5% 10px, 75.5% 10px, 46% calc(100% - 90px), 0% calc(100% - 90px), 49% 101%, 78% 10px, 87% 10px, 74% calc(100% - 90px), 0% calc(100% - 90px), 25% 100%, 0 calc(100% - 90px), 0 100%, 100% 100%, 100% 0%)  !important;-->
<!--  -webkit-clip-path: polygon(0% 0%, 0% 100%, 25.6% 89%, 66.5% 10px, 75.5% 10px, 46% calc(100% - 90px), 0% calc(100% - 90px), 49% 101%, 78% 10px, 87% 10px, 74% calc(100% - 90px), 0% calc(100% - 90px), 25% 100%, 0 calc(100% - 90px), 0 100%, 100% 100%, 100% 0%)  !important;-->
<!--}-->
<!--.slider-base{-->
<!--  width: 100%;-->
<!--  position: absolute;-->
<!--  z-index: 2;-->
<!--  bottom: 25px;-->
<!--  display: flex;-->
<!--  font-size: 32px;-->
<!--  justify-content: space-between;-->
<!--  align-items: center;-->
<!--  color: $&#45;&#45;orange;-->
<!--  padding: 0 3rem;-->
<!--}-->
<!--.arrows{-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--}-->
<!--.arrow{-->
<!--  height: 54px;-->
<!--  width: 54px;-->
<!--  border: 2px solid $&#45;&#45;orange;-->
<!--  border-radius: 50%;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  justify-content: center;-->
<!--  cursor: pointer;-->
<!--}-->
<!--.pagination-container{-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  justify-content: center;-->
<!--  height: 30px;-->
<!--}-->
<!--.dash{-->
<!--  width: 35px;-->
<!--  height: 2px;-->
<!--  background-color: $&#45;&#45;orange;-->
<!--  align-self: flex-end;-->
<!--  margin-bottom: 6px;-->
<!--  margin-right: 10px !important;-->
<!--}-->
<!--.links{-->
<!--  position: absolute;-->
<!--  height: 235px;-->
<!--  top: calc((100vh - 235px + 35px) / 2)-->
<!--}-->
<!--.links > div {-->
<!--  height: 100%;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  justify-content: space-between;-->
<!--  flex-direction: column;-->
<!--}-->
<!--.link{-->
<!--  cursor: pointer;-->
<!--}-->
<!--.links-en{-->
<!--  right: 3rem;-->
<!--}-->
<!--.links-ar{-->
<!--  left: 3rem;-->
<!--}-->
<!--.current,-->
<!--.total {-->
<!--  font-family: lama-semiBoldExpanded;-->
<!--}-->
<!--@media (min-width: 1700px) {-->
<!--  .slider-base{-->
<!--    bottom: 30px;-->
<!--  }-->
<!--}-->
<!--@media (max-width: 1200px) {-->
<!--  .layer {-->
<!--    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;-->
<!--    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;-->
<!--  }-->
<!--}-->
<!--@media screen and (max-width: 850px){-->
<!--  .slider-base,-->
<!--  .links{-->
<!--    display: none;-->
<!--  }-->
<!--}-->
<!--@media (max-height: 800px) and (min-width: 1700px){-->
<!--  .slider-base {-->
<!--    bottom: 20px-->
<!--  }-->
<!--}-->
<!--@media (max-height: 630px) {-->
<!--  .links {-->
<!--    top: calc(630px / 2 - (235px / 2))-->
<!--  }-->
<!--}-->
<!--</style>-->
<!--<style lang="scss">-->
<!--@import "../../assets/sass/main";-->
<!--ul.slick-dots{-->
<!--  position: absolute;-->
<!--  bottom: 25px;-->
<!--  padding: 0 15%;-->
<!--  display: flex !important;-->
<!--  align-items: center;-->
<!--  justify-content: space-around;-->
<!--}-->
<!--.slick-dots li button:before{-->
<!--  color: $&#45;&#45;orange;-->
<!--  font-size: 8px;-->
<!--  opacity:.4-->
<!--}-->
<!--.slick-dots li.slick-active button:before{-->
<!--  color: $&#45;&#45;orange;-->
<!--}-->
<!--@media (max-width: 550px) {-->
<!--  ul.slick-dots{-->
<!--    padding: 0 55px;-->
<!--  }-->
<!--}-->
<!--@media (max-width: 480px) {-->
<!--  ul.slick-dots{-->
<!--    bottom: 78px;-->
<!--  }-->
<!--}-->
<!--</style>-->
<!--<style>-->
<!--@media not all and (min-resolution:.001dpcm) { @media {-->
<!--  ul.slick-dots{-->
<!--      bottom: 85px;-->
<!--    }-->
<!--}}-->
<!--@media not all and (min-resolution:.001dpcm)-->
<!--{ @supports (-webkit-appearance:none) {-->
<!--  ul.slick-dots{-->
<!--    bottom: 85px;-->
<!--  }-->
<!--}}-->
<!--</style>-->
<template>
  <div id="slider">
    <VueSlickCarousel :autoplay="true" :pauseOnHover="false" :autoplaySpeed="6000" v-if="change" ref="slider" :key="componentKey" :dots="dots" :rtl="direction">
      <div :style="{'background-image': 'url(' + require(`../../assets/images/slider${i+1}.jpg`) + ')'}"   :class="[!$vuetify.rtl && 'rotate-slider' , 'home-slide']" v-for="(item, i) in info" :key="i">
        <div ref="layer" :class="['layer clip-path' , !direction && 'layer-en' ]">
        </div>
        <home-card :info="item" />
      </div>
    </VueSlickCarousel>
    <div :class="[$vuetify.rtl ? 'links-ar' : 'links-en', 'links']">
      <div>
        <a href="https://www.facebook.com/people/Doroob/61553104048638/?mibextid=ZbWKwL" target="_blank">
          <div class="link">
            <img src="../../assets/icons/facebook.svg" />
          </div>
        </a>
        <a href="https://www.instagram.com/doroob258/?igsh=NWxlMnd3M29qcGw3" target="_blank">
          <div class="link">
            <img src="../../assets/icons/instagram.svg" />
          </div>
        </a>
        <a href="https://www.youtube.com/@Doroob-gt9yy" target="_blank">
          <div class="link">
            <img src="../../assets/icons/youtube.svg" />
          </div>
        </a>
      </div>
    </div>
    <div class="slider-base" :style="$vuetify.rtl ? { 'justify-content': 'end' } : { 'justify-content': 'start' }">
<!--      <div class="arrows flex-row-reverse">-->
<!--        <div :class="$vuetify.rtl && 'rotate mr-8'" @click="handleNext" class="arrow">-->
<!--          <img src="../../assets/icons/arrow.svg" />-->
<!--        </div>-->
<!--        <div :class="!$vuetify.rtl && 'rotate mr-8'" @click="handlePrev" class="arrow">-->
<!--          <img src="../../assets/icons/arrow.svg" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div :class="[$vuetify.rtl && 'flex-row-reverse' , 'pagination-container']">-->
      <div :class="[$vuetify.rtl && 'flex-row-reverse' , 'pagination-container']">
      <div class="current mr-3">
          <span v-if="(currentSlide + 1).toString().length === 1">{{ 0 }}</span>
          <span>{{currentSlide + 1}}</span>
        </div>
        <div class="dash mr-8"></div>
        <div style="opacity: 0.5" class="total">
          <span v-if="info.length.toString().length === 1">{{ 0 }}</span>
          <span>{{info.length}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel-rtl'
import 'vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css'
import 'vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css'
import HomeCard from '../../Core/Compontents/cards/HomeCard'
export default {
  name: 'slider',
  components: { VueSlickCarousel, HomeCard },
  data () {
    return {
      dots: this.$store.state.width <= 849,
      info: [{
        title: 'الشركة السورية الأولى لفحص المركبات',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'فحص متكامل في مكان واحد',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'مركبتك بأيدي أمينة',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'اجعل السلامة هدفك الأول',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'ابدأ طريقك مع شركة دروب',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'استكشف الطرق بثقة تامة واستعد للمغامرة',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'تجربة قيادة آمنة مع دروب',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'ابدأ طريقك مع شركة دروب',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'استعد لتجربة مليئة بالثقة',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'خدمة متكاملة في مكان واحد',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'ابق أنت وعائلتك آمناً أثناء قيادتك',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      },
      {
        title: 'قيادة آمنة تعني حياة أفضل',
        desc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني الدوري للسيارات والمركبات في الجمهورية العربية السورية',
        btn: 'اكتشف المزيد'
      }],
      currentSlide: 0,
      componentKey: 1,
      direction: this.$vuetify.rtl,
      change: true
    }
  },
  mounted () {
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      this.direction = val
      this.currentSlide = 0
      this.componentKey++
      this.$refs.slider.$forceUpdate()
      setTimeout(() => {
        this.handleWatch()
      })
    })
    this.$watch(() => {
      return this.$store.state.width
    }, (val) => {
      this.dots = this.$store.state.width < 900
      this.$refs.slider.$forceUpdate()
    })
    this.handleWatch()
  },
  methods: {
    handleWatch () {
      this.$watch(() => {
        return this.$refs.slider.$children[0].currentSlide
      }, val => {
        this.currentSlide = val
      })
    },
    handleNext () {
      this.$refs.slider.next()
    },
    handlePrev () {
      this.$refs.slider.prev()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/sass/main";
#slider {
  text-align: initial;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  user-select: none;
  min-height: 630px;
  a {
    img {
      width: 22px; height: 22px;
    }
  }
}
.home-slide{
  //background-image: url("../../assets/images/mechanic.png");
  height: 100vh;
  background-repeat:no-repeat;
  background-position:center center;
  position: relative;
  background-size: cover;
  min-height: 630px;
}
.home-slide.rotate-slider::before{
  content: '';
  position: absolute;
  //background-image: url("../../assets/images/mechanic.png");
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  background-repeat:no-repeat;
  background-position:center center;
  background-size: cover;
}
.layer{
  position: absolute;
  height: 100%;
  min-height: 600px;
  width: 100%;
  background-color: $--black;
  opacity: 0.88;
  z-index: 1 !important;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 0.6% 88%, 41% 80px, 50% 80px, 21% 88%,0% 88%, 24% 101%, 52% 80px, 62% 80px, 49% 88%, 0% 88%, 25% 100%,0 88%,0 100%, 100% 100%, 100% 0%) !important;
  clip-path: polygon(0% 0%, 0% 100%, 0.6% 88%, 41% 80px, 50% 80px, 21% 88%,0% 88%, 24% 101%, 52% 80px, 62% 80px, 49% 88%, 0% 88%, 25% 100%,0 88%,0 100%, 100% 100%, 100% 0%) !important;
}
.layer-en{
  clip-path: polygon(0% 0%, 0% 100%, 25.6% 89%, 66.5% 80px, 75.5% 80px, 46% 88%, 0% 88%, 49% 101%, 78% 80px, 87% 80px, 74% 88%, 0% 88%, 25% 100%, 0 88%, 0 100%, 100% 100%, 100% 0%)  !important;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 25.6% 89%, 66.5% 80px, 75.5% 80px, 46% 88%, 0% 88%, 49% 101%, 78% 80px, 87% 80px, 74% 88%, 0% 88%, 25% 100%, 0 88%, 0 100%, 100% 100%, 100% 0%)  !important;
}
.slider-base{
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 25px;
  display: flex;
  font-size: 32px;
  //justify-content: space-between;
  justify-content: end;
  align-items: center;
  color: $--orange;
  padding: 0 3rem;
}
.arrows{
  display: flex;
  align-items: center;
}
.arrow{
  height: 54px;
  width: 54px;
  border: 2px solid $--orange;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pagination-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.dash{
  width: 35px;
  height: 2px;
  background-color: $--orange;
  align-self: flex-end;
  margin-bottom: 6px;
  margin-right: 10px !important;
}
.links{
  position: absolute;
  height: 235px;
  top: calc((100vh - 235px + 35px) / 2)
}
.links > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.link{
  cursor: pointer;
}
.links-en{
  right: 3rem;
}
.links-ar{
  left: 3rem;
}
.current,
.total {
  font-family: lama-semiBoldExpanded;
}
@media (min-width: 1700px) {
  .slider-base{
    bottom: 45px;
  }
}
@media (max-width: 1200px) {
  .layer {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
  }
}
@media screen and (max-width: 900px){
  .slider-base,
  .links{
    display: none;
  }
}
@media (max-height: 800px) and (min-width: 1700px){
  .slider-base {
    bottom: 20px
  }
}
@media (max-height: 630px) {
  .links {
    top: calc(630px / 2 - (235px / 2))
  }
}
</style>
<style lang="scss">
@import "../../assets/sass/main";
.slick-dots {
  width: 50vw;
}
ul.slick-dots {
  left: calc(25vw - 9px);
}
ul.slick-dots{
  position: absolute;
  bottom: 25px;
  //padding: 0 5%;
  display: flex !important;
  align-items: center;
  justify-content: space-around;
}
.slick-dots li button:before{
  color: $--orange;
  font-size: 8px;
  opacity:.4
}
.slick-dots li.slick-active button:before{
  color: $--orange;
}
@media (max-width: 550px) {
  .slick-dots li button:before, .slick-dots li {
    width: 17px!important; height: 17px !important;
  }
  .slick-dots li {
    margin: 0 2.5px !important;
  }
  ul.slick-dots{
    //padding: 0 5px;
  }
}
@media (max-width: 480px) {
  ul.slick-dots{
    bottom: 78px;
  }
}
</style>
<style>
@media not all and (min-resolution:.001dpcm) { @media {
  ul.slick-dots{
    bottom: 85px;
  }
}}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  ul.slick-dots{
    bottom: 85px;
  }
}}
</style>
