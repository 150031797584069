<!--<script src="../../lang/en.js"></script>-->
<!--<script src="../../lang/ar.js"></script>-->
<template>
  <div id="service_centers">
    <div :class="[!this.$vuetify.rtl && 'service-center-en', 'service_centers']">
      <div class="clip-path-mobile" v-if="width <= 1200"></div>
      <div class="main-content">
        <div class="map-container" v-observe-visibility="observePoints">
          <img class="map" :src="syriaMap">
          <img class="map-shadow" :src="syriaShadow">
          <div v-for="(city, index) in cities" :key="index" class="city-pointer">
            <div :style="{top: city.circleTop, right: city.circleRight}" class="dash-circle fade-out" :id="`circle${index}`"></div>
<!--            <div class="triangle position-absolute" :style="{top: city.numTop, right: city.numRight}"></div>-->
            <div @mouseover="overPointer(index)" :style="{top: city.numTop, right: city.numRight, cursor: 'pointer'}" class="num-container fade-out" :id="`num${index}`">
              <div v-click-outside="hideDetails" :id="`table${index}`" :style="size > 670 ? {bottom: city.tableTop, right: city.tableRight} : {bottom: city.tableTop, left: city.tableSmall}" class="center-table">
                <div class="row center-table-header">
                  <div class="col-3 centerName" :style="$vuetify.rtl ? {'border-left': '2px solid rgba(18, 17, 24, 0.28)'}:{'border-right': '2px solid rgba(18, 17, 24, 0.28)'}">{{$t('centers.centerName')}}</div>
                  <div class="col-9 centerName">{{$t('centers.address')}}</div>
                </div>
                <div style="padding: 8px 0">
                  <div class="row center-data-row" style="margin: 0;" v-for="(item, i) in $t(`centers.positions[${index}]`)" :key="i">
                    <div class="col-3 center-data-col" :style="$vuetify.rtl ? {'border-left': '2px solid rgba(18, 17, 24, 0.28)'}:{'border-right': '2px solid rgba(18, 17, 24, 0.28)'}"><span class="px-2">{{item.name}}</span> </div>
                    <div class="col-9 center-data-col" ><span class="px-2">{{item.add}}</span> <v-spacer/>  <span class="d-flex"><a class="d-md-block d-none  m-1 location-button" target="_blank" :href="`https://maps.google.com/?q=${positions[index][i].lat},${positions[index][i].lng}`">{{$t('location_info')}}</a><img src="../../assets/icons/pointer.svg"></span></div>
                  </div>
                </div>
              </div>
              <div>{{city.num}}</div>
            </div>
            <img :style="{top: city.dashesTop, right: city.dashesRight}" class="dashes fade-out" :id="`dash${index}`" src="../../assets/icons/dashes.svg">
            <div :style="{top: city.nameTop, right: city.nameRight}"  class="city-name fade-out" :id="`name${index}`">{{city.name}}</div>
          </div>
        </div>
      </div>
      <div :class="[!this.$vuetify.rtl ? 'ltr data-container-en' : 'data-container-ar', 'data-container position-relative']">
        <div :class="['service-clipper', !this.$vuetify.rtl && 'service-clipper-en']"></div>
        <div class="data">
          <div class="data-header">
            <div v-if="width > 550" class="data-num">{{centersNumber}}</div>
            <div class="data-title">
              <div class="d-flex align-center justify-center">
                <span v-if="width <= 550">{{cities.length}}</span>
                <span>{{$t('serviceCenters.title')}}</span>
              </div>
              <div class="data-mini-title">{{$t('serviceCenters.miniTitle')}}</div>
            </div>
          </div>
          <div class="data-desc">{{$t('serviceCenters.desc')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import map from '../../assets/images/syria2.svg'
import shadow from '../../assets/images/syria-shadow.svg'
import $ from 'jquery'
export default {
  name: 'ServiceCenters',
  data () {
    return {
      positions: [[
        {
          name: 'الزبلطاني',
          lat: 33.516028,
          lng: 36.329389
        },
        {
          name: 'حرستا',
          lat: 33.551006,
          lng: 36.361361
        },
        {
          name: 'النبك',
          lat: 34.001833,
          lng: 36.724278
        }
      ], [
        {
          name: 'حمص',
          lat: 34.689194,
          lng: 36.725667
        }
      ], [
        {
          name: 'حماة',
          lat: 35.132006,
          lng: 36.804306
        }
      ], [
        {
          name: 'حلب',
          lat: 36.219056,
          lng: 37.250861
        }
      ], [
        {
          name: 'طرطوس',
          lat: 34.847611,
          lng: 35.922806
        }
      ],
      [
        {
          name: 'اللاذقية',
          lat: 35.5175,
          lng: 35.814556
        }
      ]],
      mounted: false,
      syriaMap: map,
      syriaShadow: shadow,
      centersNumber: 8,
      current: null,
      size: window.innerWidth,
      cities: [
        { num: 3, circleTop: '80%', circleRight: '85%', dashesTop: 'calc(81% - 53.05px)', dashesRight: '85.4%', show: false, tableRight: '-26vw', tableTop: '44%', tableSmall: '-20vw' },
        { num: 1, circleTop: '56%', circleRight: '66%', dashesTop: 'calc(57% - 53.05px)', dashesRight: '66.4%', show: false, tableRight: '-26vw', tableTop: '44%', tableSmall: '-30vw' },
        { num: 1, circleTop: '40%', circleRight: '75%', dashesTop: 'calc(41% - 53.05px)', dashesRight: '75.4%', show: false, tableRight: '-26vw', tableTop: '44%', tableSmall: '-27vw' },
        { num: 1, circleTop: '18%', circleRight: '70%', dashesTop: 'calc(19% - 53.05px)', dashesRight: '70.4%', show: false, tableRight: '-26vw', tableTop: '-188%', tableSmall: '-31vw' },
        { num: 1, circleTop: '52%', circleRight: '92%', dashesTop: 'calc(53% - 53.05px)', dashesRight: '92.4%', show: false, tableRight: '-26vw', tableTop: '44%', tableSmall: '-14vw' },
        { num: 1, circleTop: '32%', circleRight: '93%', dashesTop: 'calc(33% - 53.05px)', dashesRight: '93.4%', show: false, tableRight: '-26vw', tableTop: '44%', tableSmall: '-14vw' }
      ],
      pcSize: [
        {
          numTop: 'calc(81.6% - 53.05px - 40px)',
          numRight: '82%',
          nameTop: '82%',
          nameRight: '72%',
          name: this.$t('stations.damascus')
        },
        {
          numTop: 'calc(58% - 53.05px - 40px)',
          numRight: '63%',
          nameTop: '58%',
          nameRight: '60%',
          name: this.$t('stations.homs')
        },
        {
          numTop: 'calc(41.5% - 53.05px - 40px)',
          numRight: '72%',
          nameTop: '41%',
          nameRight: '70%',
          name: this.$t('stations.hama')
        },
        {
          numTop: 'calc(19.5% - 53.05px - 40px)',
          numRight: '67%',
          nameTop: '19.5%',
          nameRight: '64.5%',
          name: this.$t('stations.aleppo')
        },
        {
          numTop: 'calc(55% - 53.05px - 40px)',
          numRight: '89%',
          nameTop: '54%',
          nameRight: '85%',
          name: this.$t('stations.tartus')
        },
        {
          numTop: 'calc(33.5% - 53.05px - 40px)',
          numRight: '90%',
          nameTop: '34%',
          nameRight: '85%',
          name: this.$t('stations.latakia')
        }
      ],
      numResize: [
        { numTop: 'calc(82.5% - 53.05px - 40px)', numRight: '82%' },
        { numTop: 'calc(58.5% - 53.05px - 40px)', numRight: '63%' },
        { numTop: 'calc(42.5% - 53.05px - 40px)', numRight: '72%' },
        { numTop: 'calc(20.5% - 53.05px - 40px)', numRight: '67%' },
        { numTop: 'calc(55.5% - 53.05px - 40px)', numRight: '89%' },
        { numTop: 'calc(35% - 53.05px - 40px)', numRight: '90%' }
      ],
      bigTab: [
        { numTop: 'calc(82.5% - 53.05px - 40px)', numRight: '82.6%' },
        { numTop: 'calc(58.5% - 53.05px - 40px)', numRight: '63.5%' },
        { numTop: 'calc(42.5% - 53.05px - 40px)', numRight: '72.5%' },
        { numTop: 'calc(20.5% - 53.05px - 40px)', numRight: '67.5%' },
        { numTop: 'calc(55.5% - 53.05px - 40px)', numRight: '89.5%' },
        { numTop: 'calc(35% - 53.05px - 40px)', numRight: '90.5%' }
      ],
      hugePc: [
        {
          numTop: 'calc(82% - 53.05px - 40px)',
          numRight: '82.5%',
          nameTop: '82%',
          nameRight: '72%',
          name: this.$t('stations.damascus')
        },
        {
          numTop: 'calc(58% - 53.05px - 40px)',
          numRight: '63.4%',
          nameTop: '58%',
          nameRight: '60%',
          name: this.$t('stations.homs')
        },
        {
          numTop: 'calc(41.5% - 53.05px - 40px)',
          numRight: '72.4%',
          nameTop: '41%',
          nameRight: '70%',
          name: this.$t('stations.hama')
        },
        {
          numTop: 'calc(19.5% - 53.05px - 40px)',
          numRight: '67.4%',
          nameTop: '19.5%',
          nameRight: '64.5%',
          name: this.$t('stations.aleppo')
        },
        {
          numTop: 'calc(54% - 53.05px - 40px)',
          numRight: '89.4%',
          nameTop: '54%',
          nameRight: '85%',
          name: this.$t('stations.tartus')
        },
        {
          numTop: 'calc(33.5% - 53.05px - 40px)',
          numRight: '90.4%',
          nameTop: '34%',
          nameRight: '85%',
          name: this.$t('stations.latakia')
        }
      ],
      miniLaptop: [
        { numTop: 'calc(90.5% - 53.05px - 40px)', numRight: '80%', nameTop: '82%', nameRight: '68%' },
        { numTop: 'calc(76.5% - 53.05px - 40px)', numRight: '65%', nameTop: '68%', nameRight: '60%' },
        { numTop: 'calc(56.5% - 53.05px - 40px)', numRight: '70%', nameTop: '48%', nameRight: '65%' },
        { numTop: 'calc(36.5% - 53.05px - 40px)', numRight: '67%', nameTop: '28%', nameRight: '63%' },
        { numTop: 'calc(63.5% - 53.05px - 40px)', numRight: '88%', nameTop: '55%', nameRight: '80%' },
        { numTop: 'calc(46% - 53.05px - 40px)', numRight: '90%', nameTop: '38%', nameRight: '85%' }
      ],
      mediumSize: [
        { numTop: '75%', numRight: '82%', nameTop: '70%', nameRight: '77%', name: this.$t('stations.damascusMobile') },
        { numTop: '57%', numRight: '67%', nameTop: '51.5%', nameRight: '67%', name: this.$t('stations.homsMobile') },
        { numTop: '41%', numRight: '70%', nameTop: '37.5%', nameRight: '70%', name: this.$t('stations.hamaMobile') },
        { numTop: '15%', numRight: '67%', nameTop: '11%', nameRight: '68%', name: this.$t('stations.aleppoMobile') },
        { numTop: '48%', numRight: '89%', nameTop: '43%', nameRight: '88%', name: this.$t('stations.tartusMobile') },
        { numTop: '30%', numRight: '90%', nameTop: '25.5%', nameRight: '88%', name: this.$t('stations.latakiaMobile') }
      ],
      smallSize: [
        { numTop: '75%', numRight: '79%', nameTop: '68%', nameRight: '71%', name: this.$t('stations.damascusMobile') },
        { numTop: '58%', numRight: '66%', nameTop: '52%', nameRight: '66%', name: this.$t('stations.homsMobile') },
        { numTop: '39%', numRight: '70%', nameTop: '34%', nameRight: '70%', name: this.$t('stations.hamaMobile') },
        { numTop: '17%', numRight: '65%', nameTop: '11%', nameRight: '66%', name: this.$t('stations.aleppoMobile') },
        { numTop: '46%', numRight: '87%', nameTop: '40%', nameRight: '84%', name: this.$t('stations.tartusMobile') },
        { numTop: '30%', numRight: '89%', nameTop: '24%', nameRight: '87%', name: this.$t('stations.latakiaMobile') }
      ],
      width: this.$store.state.width,
      timeOutId: null
    }
  },
  created () {
    this.handlePositions()
    window.addEventListener('resize', this.handlePositions)
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      const stationsForPc = [this.$t('stations.damascus'), this.$t('stations.homs'), this.$t('stations.hama'),
        this.$t('stations.aleppo'), this.$t('stations.tartus'), this.$t('stations.latakia')]
      const stationsForMobile = [this.$t('stations.damascusMobile'), this.$t('stations.homsMobile'), this.$t('stations.hamaMobile'),
        this.$t('stations.aleppoMobile'), this.$t('stations.tartusMobile'), this.$t('stations.latakiaMobile')]
      for (let i = 0; i < this.cities.length; i++) {
        this.pcSize[i] = { ...this.pcSize[i], name: stationsForPc[i] }
        this.hugePc[i] = { ...this.hugePc[i], name: stationsForPc[i] }
        this.mediumSize[i] = { ...this.mediumSize[i], name: stationsForMobile[i] }
        this.smallSize[i] = { ...this.smallSize[i], name: stationsForMobile[i] }
      }
      this.handlePositions()
    })
    window.addEventListener('resize', this.handleSize)
  },
  mounted () {
    clearTimeout(this.timeOutId)
    this.cities.forEach((point, index) => {
      document.getElementById(`table${index}`).style.display = 'none'
      document.getElementById(`circle${index}`).style.opacity = 0
      document.getElementById(`dash${index}`).style.opacity = 0
      document.getElementById(`num${index}`).style.opacity = 0
      document.getElementById(`name${index}`).style.opacity = 0
    })
    this.mounted = true
    // document.getElementById(`table0`).style.opacity = 1
  },
  methods: {
    handleSize () {
      this.size = window.innerWidth
    },
    // parseLocationString(locationString) {
    //   const parts = locationString.split(" ");
    //   const latDegrees = parseFloat(parts[0].split("°")[0]);
    //   const latMinutes = parseFloat(parts[0].split("°")[1].split("'")[0]);
    //   const latSeconds = parseFloat(parts[0].split("'")[1].slice(0, -1)); // Remove trailing "N"
    //
    //   const lngDegrees = parseFloat(parts[2].split("°")[0]);
    //   const lngMinutes = parseFloat(parts[2].split("°")[1].split("'")[0]);
    //   const lngSeconds = parseFloat(parts[2].split("'")[1].slice(0, -1)); // Remove trailing "E"
    //
    //   this.lat = latDegrees + (latMinutes / 60) + (latSeconds / 3600);
    //   this.lng = lngDegrees + (lngMinutes / 60) + (lngSeconds / 3600);
    // },
    hideDetails () {
      if (this.mounted) {
        this.cities.forEach((point, index) => {
          if (this.current !== index) {
            document.getElementById(`table${index}`).style.display = 'none'
            document.getElementById(`table${index}`).style.pointerEvents = 'none'
            document.getElementById(`circle${index}`).style.opacity = 1
            document.getElementById(`dash${index}`).style.opacity = 1
            document.getElementById(`num${index}`).style.opacity = 1
            document.getElementById(`name${index}`).style.opacity = 1
            this.current = null
          }
        })
      }
    },
    overPointer (i) {
      $('.fade-out').css('transition', 'none')
      document.getElementById(`table${i}`).style.display = 'block'
      document.getElementById(`table${i}`).style.pointerEvents = 'all'
      document.getElementById(`circle${i}`).style.opacity = 1
      document.getElementById(`dash${i}`).style.opacity = 1
      document.getElementById(`num${i}`).style.opacity = 1
      document.getElementById(`name${i}`).style.opacity = 1
      this.cities.forEach((point, index) => {
        if (index !== i) {
          document.getElementById(`table${index}`).style.display = 'none'
          document.getElementById(`table${index}`).style.pointerEvents = 'none'
          document.getElementById(`circle${index}`).style.opacity = 0.5
          document.getElementById(`dash${index}`).style.opacity = 0.5
          document.getElementById(`num${index}`).style.opacity = 0.5
          document.getElementById(`name${index}`).style.opacity = 0.5
        }
      })
      this.current = i
    },
    observePoints (val) {
      if (val) {
        $('.fade-out').css('transition', 'opacity 1s ease-in-out')
        this.cities.forEach((point, index) => {
          this.timeOutId = setTimeout(() => {
            document.getElementById(`circle${index}`).style.opacity = 1
            document.getElementById(`dash${index}`).style.opacity = 1
            document.getElementById(`num${index}`).style.opacity = 1
            document.getElementById(`name${index}`).style.opacity = 1
          }, (index + 1) * 350)
        })
      } else {
        clearTimeout(this.timeOutId)
        this.cities.forEach((point, index) => {
          document.getElementById(`table${index}`).style.display = 'none'
          document.getElementById(`table${index}`).style.pointerEvents = 'none'
          document.getElementById(`circle${index}`).style.opacity = 0
          document.getElementById(`dash${index}`).style.opacity = 0
          document.getElementById(`num${index}`).style.opacity = 0
          document.getElementById(`name${index}`).style.opacity = 0
        })
      }
    },
    handlePositions () {
      this.width = window.innerWidth
      if (window.innerWidth < 900 && window.innerWidth >= 550) {
        for (let i = 0; i < this.cities.length; i++) {
          this.cities[i] = {
            ...this.cities[i],
            numTop: this.mediumSize[i].numTop,
            numRight: this.mediumSize[i].numRight,
            nameTop: this.mediumSize[i].nameTop,
            nameRight: this.mediumSize[i].nameRight,
            name: this.mediumSize[i].name
          }
        }
      } else if (window.innerWidth < 550) {
        for (let i = 0; i < this.cities.length; i++) {
          this.cities[i] = {
            ...this.cities[i],
            numTop: this.smallSize[i].numTop,
            numRight: this.smallSize[i].numRight,
            nameTop: this.smallSize[i].nameTop,
            nameRight: this.smallSize[i].nameRight,
            name: this.smallSize[i].name
          }
        }
      } else {
        for (let i = 0; i < this.cities.length; i++) {
          this.cities[i] = {
            ...this.cities[i],
            numTop: this.pcSize[i].numTop,
            numRight: this.pcSize[i].numRight,
            nameTop: this.pcSize[i].nameTop,
            nameRight: this.pcSize[i].nameRight,
            name: this.pcSize[i].name
          }
        }
        if (window.innerWidth >= 1800) {
          for (let i = 0; i < this.cities.length; i++) {
            this.cities[i] = {
              ...this.cities[i],
              numTop: this.hugePc[i].numTop,
              numRight: this.hugePc[i].numRight,
              nameTop: this.hugePc[i].nameTop,
              nameRight: this.hugePc[i].nameRight,
              name: this.hugePc[i].name
            }
          }
        } else if (window.innerWidth <= 1400 && window.innerWidth > 1200) {
          for (let i = 0; i < this.cities.length; i++) {
            this.cities[i] = {
              ...this.cities[i],
              numTop: this.miniLaptop[i].numTop,
              numRight: this.miniLaptop[i].numRight,
              nameTop: this.miniLaptop[i].nameTop,
              nameRight: this.miniLaptop[i].nameRight
            }
          }
        } else if (window.innerWidth >= 1000 && window.innerWidth <= 1200) {
          for (let i = 0; i < this.cities.length; i++) {
            this.cities[i] = {
              ...this.cities[i],
              numTop: this.bigTab[i].numTop,
              numRight: this.bigTab[i].numRight
            }
          }
        } else if (window.innerWidth < 1000) {
          for (let i = 0; i < this.cities.length; i++) {
            this.cities[i] = {
              ...this.cities[i],
              numTop: this.numResize[i].numTop,
              numRight: this.numResize[i].numRight
            }
          }
        }
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/sass/main";
//center data table styles
.triangle {
  pointer-events: none;
  transform: rotate(317deg);
  display: block;
  height: 19px;
  width: 20px;
  /*right: calc(4vw + 28px);*/
  z-index:  499001;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  //transform: rotate(138deg);
  //border-radius: 0 0 0 5px;
  //background: rgb(242, 242, 242, 0.9);
  background: red;
  backdrop-filter: blur(10px);
}
.center-table {
  cursor: default;
  min-width: 280px; width: 32vw; pointer-events: none ;
  z-index: 100; position: absolute ;
  border-radius: 30px;
  background: rgba(247, 247, 247, 0.7); backdrop-filter: blur(25px); margin: 2rem;
}
.center-table-header {
  background: rgba(255, 172, 0, 1);
  height: 78px;
  margin: 0; padding: 8px 0;
  border-radius: 30px 30px 0 0;
}
.center-data-row {
  border-bottom: 2px solid rgba(18, 17, 24, 0.28);
}
.center-data-row:last-child {
  border: none;
}
.centerName {
  display: flex; align-items: center; justify-content: center;
  font-family: lama-bold-expanded; font-size: 14px; color: #121118;
}
.location-button {
  color: #FFAC00 !important;
  font-family: lama-extraBoldExpanded; font-size: 13px; text-decoration: underline !important;
  cursor: pointer; pointer-events: all
}
.center-data-col {
  display: flex; justify-content: center; align-items: center;
  font-family: lama-medium; font-size: 14px; color: #121118;
}
//end of center data table styles

.fade-out {
  transition: opacity 1s ease-in-out; /* Transition for smooth fade-in */
}
.no-fade-out {
  transition: none !important;
}
#service_centers {
  margin-top: -70px;
  padding-top: 70px;
}
.service_centers{
  display: flex;
  padding: 2rem 0;
  background-color: #F7F7F7;
}
.service-center-en{
  padding-left: 3.5rem !important;
}
.main-content{
  width: 50%;
}
.map-container{
  position: relative;
  width: 85%;
  margin: 0 auto;
  min-height: 500px
}
.map-container{
  height: 100%
}
.map-container > .map{
  width: 100%;
  height: 100%;
}
.data-container-ar{
  margin-right: 45px;
}
.data-container-en{
  margin-left: 45px;
}
.map-container > .map-shadow{
  position: absolute;
  right: -70px;
  width: 100%;
  top: 35px;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.num-container{
  width: 45px;
  height: 45px;
  border: 4px solid $--orange;
  background: $--black;
  border-radius: 50%;
  position: absolute;
  color: $--orange;
  display: flex;
  align-items: center;
  justify-content: center;
}
.num-container > div {
  font-family: lama-bold-expanded;
  font-size: 14px;
}
.dashes{
  position: absolute;
}
.dash-circle {
  height: 8.5px;
  width: 8.5px;
  border: 3px solid $--orange;
  border-radius: 50%;
  position: absolute;
}
.city-name{
  color: $--black;
  font-family: lama-extraBoldExpanded;
  position: absolute;
  width: max-content;
  max-width: 200px;
  font-size: 14px;
}
.data-container{
  width: 50%;
}
.service-clipper{
  background: #fff;
  clip-path: polygon(45.843% 100%, 79.668% 0%, 99% 0%, 86% 100%, -23% 100%, 51.256% 0%, 73.99% 0%, 21.595% 100%);
  -webkit-clip-path: polygon(45.843% 100%, 79.668% 0%, 99% 0%, 86% 100%, -23% 100%, 51.256% 0%, 73.99% 0%, 21.595% 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.service-clipper-en{
  clip-path: polygon(57.843% 100%, 95.668% 0%, 100% 0%, 100% 100%, 0% 100%, 71.256% 0%, 90.99% 0%, 39.595% 100%);
  -webkit-clip-path: polygon(57.843% 100%, 95.668% 0%, 100% 0%, 100% 100%, 0% 100%, 71.256% 0%, 90.99% 0%, 39.595% 100%)
}
.clip-path-mobile{
  margin-top: 28px;
  height:250px;
  width: 100%;
  background: #fff;
  clip-path: polygon(79.843% 80%, 91% 0%, 100% 0%, 100% 80%, -8% 80%, 20.256% 0%, 87.5% 0%, 71.595% 80%);
  -webkit-clip-path: polygon(79.843% 80%, 91% 0%, 100% 0%, 100% 80%, -8% 80%, 20.256% 0%, 87.5% 0%, 71.595% 80%);
}
.data{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 6.3rem;
}
.data-header{
  display: flex;
  align-items: center;
}
.data-num{
  font-size: 100px;
  color: $--orange;
  font-family: lama-extraBoldExpanded;
  text-shadow: 2px 5px 15px rgba(0,0,0,0.3);
  margin: 0;
}
.data-title > div > span{
  color: $--orange;
  font-family: lama-bold-expanded;
  font-size: 29px;
  margin: 0 1rem;
}
.data-mini-title{
  color: $--black;
  font-family: lama-bold-expanded;
  font-size: 12px;
}
.data-desc {
  font-family: lama-medium;
  text-align: initial;
  font-size: 18px;
  width: 95%;
}
@media (max-width: 1500px) {
  .data{
    top: 5rem
  }
}
@media (max-width: 1400px) {
  .num-container{
    width: 40px;
    height: 40px;
    border: 4px solid $--orange;
  }
}
@media (max-width: 1400px) and (min-width: 1201px){
  .dash-circle,
  .dashes{
    display: none;
  }
  .map-container{
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .service-center-en{
    padding-left: 0 !important;
  }
  .service-clipper, {
    display:  none;
  }
  .data {
    position: unset;
  }
  .data-container-en{
    margin-left: 0px;
  }
  .data-container-ar{
    margin-right: 0px;
  }
  .service_centers{
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
    margin: 2rem 0 3rem 0;
  }
  .data-container{
    background-image: none;
    width: 92%;
    padding: 0px !important;
  }
  .main-content{
    width: 75%;
  }
  .data-header{
    justify-content: center;
  }
  .data-desc{
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .map-container > .map-shadow {
    right: -35px;
    top: 20px;
  }
  .city-name{
    font-size: 11px;
  }
  .dashes,
  .dash-circle{
    display: none;
  }
  .data-num{
    font-size: 75px;
  }
  .data-title > div > span {
    color: #FFAC00;
    font-family: lama-bold-expanded;
    font-size: 25px;
    margin: 0 1rem;
  }
  .data-desc{
    font-size: 15px;
    width: 98%;
    margin: 0 auto 20px auto;
  }
  .num-container{
    width: 32px;
    height: 32px;
    border: 3px solid $--orange;
  }
  .num-container > div {
    font-family: lama-bold-expanded;
    font-size: 10px;
  }
  .service_centers{
    padding: 0 0rem;
  }
}
@media (max-width: 350px) {
  .center-table {
    display: none !important;
  }
}
@media (max-width: 900px) {
  .clip-path-mobile{
    height:200px;
  }
}
@media (max-width: 800px) {
  .map-container{
    min-height: 350px
  }
}
@media (max-width: 750px) {
  .clip-path-mobile{
    height:170px;
  }
}
@media (max-width: 650px) {
  .center-data-col, .centerName {
    font-size: 13px;
  }
  .main-content{
    width: 80%
  }
}
@media (max-width: 600px) {
  .clip-path-mobile {
    height: 140px;
    clip-path: polygon(79.843% 100%, 91.0% 0%, 100% 0%, 100% 100%, -8% 100%, 20.256% 0%, 87.5% 0%, 71.595% 100%);
    -webkit-clip-path: polygon(79.843% 100%, 91.0% 0%, 100% 0%, 100% 100%, -8% 100%, 20.256% 0%, 87.5% 0%, 71.595% 100%) !important;
  }
}
@media (max-width: 551px) {
  .clip-path-mobile{
    height:125px;
  }
  .data-title > div > span{
    font-size: 23px;
    margin: 0
  }
  .data-title > div > span:first-child{
    font-size: 50px;
    text-shadow: 2px 5px 15px rgba(0,0,0,0.3);
  }
  .data-mini-title{
    font-size: 11px;
    margin-top: -16px
  }
  .data-desc{
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px
  }
  .map-container {
    width: 100%;
  }
  .data-desc{
    font-size: 13px;
  }
  .city-name {
    font-size: 10px;
  }
  .num-container {
    width: 25px;
    height: 25px;
    border: 3px solid $--orange;
  }
  .num-container > div {
    font-size: 10px;
  }
}
@media (max-width: 450px) {
  .map-container{
    min-height: 300px
  }
  .data-title > div > span:first-child{
    font-size: 42px
  }
}
@media (max-width: 370px) {
    .map-container{
      min-height: 240px
    }
}
@media (max-width: 330px) {
  .data-title > div > span{
    font-size: 20px;
  }
}
@media (max-width: 315px) {
  .map-container{
    min-height: 220px
  }
}
@media (max-width: 260px) {
  .map-container{
    min-height: 200px
  }
}
@media (max-width: 250px) {
  .map-container{
    min-height: unset;
  }
}
</style>
